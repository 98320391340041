
import { Component, Prop, Vue } from "vue-property-decorator";
import metaDataModule from "@/store/modules/metaDataModule";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";
import { getView, sleep } from "@/utils/helpers";
import TasqJob from "@/interfaces/tasqs/TasqJob";

@Component({
  components: {
    FormFieldPreview: () => getView("FormFieldPreview"),
  },
})
export default class TasqWellActionFeedback extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  actionItems: any = [];

  sortActionsInAscendingOrder = false;

  hidePreviewFieldSection = false;

  formDetailsLoading = false;

  searchQuery = "";

  selectedActions: any = [];

  isLoadingDetails = false;

  get selectedActionInputTab() {
    return tasqFeedbackModule.selectedActionInputTab;
  }

  created() {
    this.setupActionDetails();
  }

  async setupActionDetails(notUpdateTab = false) {
    this.isLoadingDetails = true;
    const localFormsList: any = [];
    const selectedForms = tasqFeedbackModule.selectedForms;
    selectedForms.forEach(async (form, i) => {
      const localFormDetail: any = {};
      Object.assign(localFormDetail, form);
      let fromDetails: any = await metaDataModule.getActionsBuilderRecords({
        actionBuilderID: localFormDetail.id,
      });

      if (fromDetails.constructor === Array) {
        // eslint-disable-next-line prefer-destructuring
        fromDetails = fromDetails[0];
      }

      localFormDetail.dateOfDisplay = fromDetails.dateOfDisplay;

      if (fromDetails && fromDetails.actionPayload) {
        if (localFormDetail && localFormDetail.fields && localFormDetail.fields.length) {
          localFormDetail.fields = fromDetails.actionPayload.map((field) => {
            const fieldReturnObj = field;
            const localField = localFormDetail.fields.find(
              (responseField) => responseField.id === fieldReturnObj.id
            );
            if (localField && localField.localVal) {
              fieldReturnObj.localVal = localField.localVal;
            } else {
              fieldReturnObj.localVal = "";
            }
            return fieldReturnObj;
          });
        } else {
          localFormDetail.fields = fromDetails.actionPayload.map((field) => {
            const fieldReturnObj = field;
            fieldReturnObj.localVal = "";
            return fieldReturnObj;
          });
        }
      } else {
        localFormDetail.fields = [];
      }

      // @ts-ignore
      const actionLastResponse: any =
        this.tasq && this.tasq.predictionsList
          ? this.tasq.predictionsList[form.category]
          : null;
      if (actionLastResponse && actionLastResponse.CompletedForToday) {
        localFormDetail.isCompleteForToday = true;
      }

      if (localFormDetail.fields) {
        localFormsList.push(localFormDetail);
      }
    });

    await sleep(3000);
    const sortedSelectedFormsList = localFormsList.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    this.selectedActions = sortedSelectedFormsList;
    tasqFeedbackModule.setSelectedActionDetails(this.selectedActions[0]);
    tasqFeedbackModule.setSelectedActionInputTab(this.selectedActions[0]?.id);
    this.isLoadingDetails = false;
  }

  refreshForm() {
    this.formDetailsLoading = true;
    this.$nextTick(() => {
      this.formDetailsLoading = false;

      this.$forceUpdate();
    });
  }

  updateFieldValue(payload) {
    const formIndex = this.selectedActions.findIndex(
      (form) => form.id === payload.formId
    );
    if (formIndex > -1) {
      const fieldIndex = this.selectedActions[formIndex].fields.findIndex(
        (field) => field.id === payload.fieldId
      );
      if (fieldIndex > -1) {
        const formField = JSON.parse(
          JSON.stringify(this.selectedActions[formIndex].fields[fieldIndex])
        );
        if (formField && formField.category === "Dropdown") {
          this.hidePreviewFieldSection = true;
        }
        formField.localVal = payload.value;
        this.selectedActions[formIndex].fields[fieldIndex] = formField;
      }
    }
    this.$nextTick(() => {
      this.hidePreviewFieldSection = false;
    });
  }

  validateFields(fields) {
    const errors: any = [];
    fields.forEach((field) => {
      const errorExist = this.$refs[field.id]![0].validateField();
      if (errorExist) {
        errors.push(errorExist);
      }
    });

    return errors;
  }

  async submitInput() {
    const actionDetails = this.selectedActions.find(
      (input) => input.id === this.selectedActionInputTab
    );
    if (actionDetails && actionDetails.fields && actionDetails.fields.length) {
      this.hidePreviewFieldSection = true;
      this.$nextTick(() => {
        this.hidePreviewFieldSection = false;
      });

      await sleep(100);

      const errors = this.validateFields(actionDetails.fields);
      console.error(errors);
      if (errors && errors.length) {
        return false;
      }
    }

    const currentFormIndex = this.selectedActions.findIndex(
      (form) => form.id === this.selectedActionInputTab
    );

    this.selectedActions[currentFormIndex].validateForToday = true;

    const nextForm = this.selectedActions.find((form) => form.validateForToday === false);

    if (nextForm) {
      tasqFeedbackModule.setSelectedActionDetails(nextForm);
      tasqFeedbackModule.setSelectedActionInputTab(nextForm.id);
      return false;
    }
    tasqFeedbackModule.setSeletedForms(this.selectedActions);
    return true;
  }
}
